import { useState, useEffect } from "react";

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
  
      window.addEventListener("resize", handleResize, { passive: true });
      handleResize();
  
      return () => {
        window.removeEventListener("resize", handleResize, { passive: true });
      };
    }, []);
  
    return windowSize;
  };