import gql from "graphql-tag";

const ADD_TO_CART = gql`
  mutation ($input: AddToCartInput!) {
    addToCart(input: $input) {
      cartItem {
        key
        quantity
        total
      }
    }
  }
`;

export default ADD_TO_CART;
