const ThirdParty = process.env.NEXT_PUBLIC_USE_TERCEROS;

export const trackAddToCartEventTikTok = (action, params) => {
    if (ThirdParty != "FALSE") {
        if (window.ttq) {
            window.ttq.track(action, params);
        }
    }
};

export const itemsTikTok = (items) => {

    let parseItems = [];
    items?.products?.map(function (item) {
        parseItems.push({
            "content_id": item.codigoERP,
            "content_name": item.name,
            "content_type": "product",
        });
    });
    return parseItems;
};

export const itemsTikTokPayment = (items) => {

    let parseItems = [];
    items?.products?.map(function (item) {
        parseItems.push({
            "content_id": item.codigoERP,
            "content_name": item.name,
            "content_type": "product",
            "price": item.price,
            "quantity": item.qty
        });
    });
    return parseItems;
};


/*
ttq.identify({
    "email": "<hashed_email_address>", // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
    "phone_number": "<hashed_phone_number>", // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
    "external_id": "<hashed_extenal_id>" // string. A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
})
ttq.track('ViewContent', {
    "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
    "currency": "<content_currency>", // string. The 4217 currency code. Example: "USD".
    "contents": [
        {
            "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
            "content_type": "<content_type>", // string. Either product or product_group.
            "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
        }
    ]
})
ttq.track('AddToWishlist', {
    "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
    "currency": "<content_currency>", // string. The 4217 currency code. Example: "USD".
    "contents": [
        {
            "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
            "content_type": "<content_type>", // string. Either product or product_group.
            "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
        }
    ]
})
ttq.track('Search', {
    "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
    "currency": "<content_currency>", // string. The 4217 currency code. Example: "USD".
    "contents": [
        {
            "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
            "content_type": "<content_type>", // string. Either product or product_group.
            "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
        }
    ],
    "query": "<search_keywords>" // string. The word or phrase used to search. Example: "SAVE10COUPON".
})
ttq.track('AddPaymentInfo', {
    "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
    "currency": "<content_currency>", // string. The 4217 currency code. Example: "USD".
    "contents": [
        {
            "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
            "content_type": "<content_type>", // string. Either product or product_group.
            "content_name": "<content_name>", // string. The name of the page or product. Example: "shirt".
            "price": "<content_price>", // number. The price of a single item. Example: 25.
            "quantity": "<content_quantity>" // number. The number of items. Example: 4.
        }
    ]
})
ttq.track('AddToCart', {
    "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
    "currency": "<content_currency>", // string. The 4217 currency code. Example: "USD".
    "contents": [
        {
            "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
            "content_type": "<content_type>", // string. Either product or product_group.
            "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
        }
    ]
})
ttq.track('InitiateCheckout', {
    "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
    "currency": "<content_currency>", // string. The 4217 currency code. Example: "USD".
    "contents": [
        {
            "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
            "content_type": "<content_type>", // string. Either product or product_group.
            "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
        }
    ]
})
ttq.track('PlaceAnOrder', {
    "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
    "currency": "<content_currency>", // string. The 4217 currency code. Example: "USD".
    "contents": [
        {
            "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
            "content_type": "<content_type>", // string. Either product or product_group.
            "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
        }
    ]
})
ttq.track('CompleteRegistration', {
    "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
    "currency": "<content_currency>", // string. The 4217 currency code. Example: "USD".
    "contents": [
        {
            "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
            "content_type": "<content_type>", // string. Either product or product_group.
            "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
        }
    ]
})
ttq.track('CompletePayment', {
    "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
    "currency": "<content_currency>", // string. The 4217 currency code. Example: "USD".
    "contents": [
        {
            "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
            "content_type": "<content_type>", // string. Either product or product_group.
            "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
        }
    ]
})

*/
