/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowSize } from "../../../hooks/useWindowsSize";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Mousewheel]);

const SwiperCarousel = ({ children, title, isLanding }) => {
    const [marginLeftFirst, setMarginLeftFirst] = useState();
    const [marginRightLast, setMarginRightLast] = useState();
    const [showPrevButton, setShowPrevButton] = useState(false);
    const [showNextButton, setShowNextButton] = useState(true);
    const swiperRef = useRef({});
    const windowSize = useWindowSize();

    const updatePadding = () => {
        const titleElement = document.querySelector(".slider-main-title");
        const titleOffsetLeft = titleElement.getBoundingClientRect().left;

        if (window.innerWidth < 640) {
            setMarginLeftFirst(titleOffsetLeft + 16);
        }
        if (window.innerWidth >= 640) {
            setMarginLeftFirst(titleOffsetLeft + 32);
        }
        if (window.innerWidth >= 768) {
            setMarginLeftFirst(titleOffsetLeft + 64);
        }
        if (window.innerWidth >= 1024) {
            setMarginLeftFirst(titleOffsetLeft + 128);
        }
        if (window.innerWidth >= 1280) {
            setMarginLeftFirst(titleOffsetLeft + 128);
        }
        if (window.innerWidth >= 1536) {
            setMarginLeftFirst(titleOffsetLeft + 256);
        }

        const containerElement = document.querySelector(".swiper-container");
        const containerWidth = containerElement.getBoundingClientRect().width;
        const marginRight = containerWidth - titleOffsetLeft - 412; // 412px es el ancho del elemento
        setMarginRightLast(marginRight);
    };

    useEffect(() => {
        updatePadding();
    }, [windowSize.width]);

    const handleSwiper = (swiper) => {
        if (!swiper) return;
      
        swiperRef.current = swiper;
      
        swiper.on("init", () => {
          updateButtonsState(swiper);
        });
      
        swiper.on("slideChange", () => {
          updateButtonsState(swiper);
        });
      };
    
      const updateButtonsState = (swiper) => {
        if (swiper.isBeginning) {
          setShowPrevButton(false);
          swiper.params.slidesPerGroup = 1;
        } else {
          setShowPrevButton(true);
        }
        if (swiper.isEnd) {
          setShowNextButton(false);
          swiper.params.slidesPerGroup = 1;
        } else {
          setShowNextButton(true);
        }
      };

    return (
        <div className="swiper-container">
            <div>
                <h2 className={`${isLanding ? "pl-[16px]" : "pl-[32px]"} slider-main-title capitalize text-left mt-4 mb-6 sm:pl-[32px] md:pl-[64px] lg:pl-[128px] xl:pl-[128px] 2xl:pl-[256px]`}>
                    <span className="text-lg lg:text-2xl font-helvetica-text font-medium antialiased">{title}</span>
                </h2>
            </div>

            <Swiper
                onSwiper={handleSwiper}
                slidesPerView="auto"
                spaceBetween={16}              
                resistance={true}
                resistanceRatio={0} // Desactivar el efecto elástico
                mousewheel={{ forceToAxis: true }} // Agrega esta propiedad
                autoplay={false}
                breakpoints={{
                    1280: {
                        slidesPerView: 4,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    640: {
                        slidesPerView: 2,
                    },
                    280: {
                        slidesPerView: 2,
                    },
                }}
            >
                {/*showPrevButton ? <div className="absolute top-0 left-0 bottom-0 w-0 md:w-10 z-10 bg-gradient-to-r from-white"></div> : <></>*/}
                {React.Children.map(children, (Child, index) => (
                    <SwiperSlide
                        key={index}
                        className="xl:w-[412px] lg:w-[350px] md:w-[300px]"
                        style={{                            
                            marginLeft: index === 0 ? marginLeftFirst + "px" : 0,
                            marginRight: index === children.length - 1 ? marginRightLast + "px" : 0,                            
                        }}
                    >
                        {/* para el style minWidth: windowSize.width <= 640 ? "300px" : "", */}
                        {/* Contenido del elemento */}
                        <div className={`${index === children.length - 1 ? "hidden" : "block"}`}>{Child}</div>
                    </SwiperSlide>
                ))}
                {/*showNextButton ? <div className="absolute top-0 right-0 bottom-0 w-0 md:w-10 z-10 bg-gradient-to-l from-white"></div> : <></>*/}
            </Swiper>
            {showPrevButton && (
                <div
                    className="swiper-button-prev rounded-full bg-white border border-gray-normal lg:hover:border-2"
                    onClick={() => swiperRef.current && swiperRef.current.slidePrev()}
                ></div>
            )}
            {showNextButton && (
                <div
                    className="swiper-button-next rounded-full bg-white border border-gray-normal lg:hover:border-2"
                    onClick={() => swiperRef.current && swiperRef.current.slideNext()}
                ></div>
            )}
            <style jsx>{`               
                .swiper-button-prev,
                .swiper-button-next {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                background: white;
                box-shadow: 0 3px 12px rgba(0,0,0,.15);
                animation: fadeIn 0.3s;
                transition: opacity 0.4s;
                cursor: pointer;
                top: 45% !important;
                z-index: 20 !important;
                }
                .swiper-button-prev:hover,
                .swiper-button-next:hover {
                border-width: 2px;
                }
                .swiper-button-prev::after,
                .swiper-button-next::after {
                font-size: 18px;
                color: black;
                }             
                @media (max-width: 1023px) {
                    .swiper-button-prev,
                    .swiper-button-next {
                        display: none;
                    }
                }  
            `}</style>

        </div>
    );
};

export default SwiperCarousel;
