const ThirdParty = process.env.NEXT_PUBLIC_USE_TERCEROS;

export const fbq = (action, params) => {
	if (ThirdParty != "FALSE") {
		window.fbq("track", action, params);
	}
};

export const itemsMeta = (items) => {

	let parseItems = [];
	items?.products?.map(function (item) {
		parseItems.push({
			"id": "wc_post_id_" + item.variationId,
			"quantity": item.qty
		});
	});
	return parseItems;
};