/* eslint-disable react/prop-types */
import React from "react";

function NewProduct({ fechaAdmision }) {
  // Obtén la fecha actual
  const fechaActual = new Date();

  // Calcula la fecha hace un mes
  const haceUnMes = new Date();
  haceUnMes.setMonth(haceUnMes.getMonth() - 1);

  // Convierte la fecha de admisión en un objeto Date
  const fechaAdmisionDate = new Date(fechaAdmision);

  // Comprueba si la fecha de admisión está dentro del último mes
  const esNuevo = fechaAdmisionDate >= haceUnMes && fechaAdmisionDate <= fechaActual;

  return (
    <div>
      {esNuevo ? <p className="font-helvetica-text font-normal text-sm md:text-base text-green-new mb-1.5">Nuevo</p> : ""}
    </div>
  );
}

export default NewProduct;
